import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const ConcomitantVaccinationService = {
    Read,
    Download,
    ReadMostProduct
};

async function Read(page = 1, limit = 10, query, params = {}, yearType = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/summary-report/concomitant-vaccination?page=${page}&limit=${limit}&query=${query}&yearType=${yearType}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}



async function Download(yearType = "") {
    fetch('/api/v1/summary-report/concomitant-vaccine/xls?yearType=' + yearType, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        .then(response => {
            var filename = "";
            var disposition = response.headers.get("content-disposition");
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }

            response.blob().then(result => {
                const url = window.URL.createObjectURL(result);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            })
        })
}

async function ReadMostProduct(yearType = "", year = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/summary-report/concomitant-vaccine-aefi-most-product?yearType=${yearType}&year=${year}`

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}