import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components';

const ButtonStyled = styled.button.attrs(props => ({ ...props }))`
    background: ${props => props.background ? props.background : "#00ABBD 0% 0% no-repeat padding-box"};
    border-radius: 6px;
    opacity: 1;
    text-align: center;
    font: normal normal 16px/16px Montserrat;
    letter-spacing: 0px;
    color: ${props => props.color ? props.color : "#fff"};
    max-width : ${props => props.maxwidth ? props.maxwidth : "100%"};
    width: ${props => props.width ? props.width : "100%"};
    height: ${props => props.height ? props.height : "fit-content"};
    border: ${props => props.border ? props.border : "0"};
    padding: ${props => props.padding ? props.padding : "12px 15px"};
`

const ButtonIconStyled = styled.i.attrs(props => ({ ...props }))`
    margin-right: 10%;
`

export default class ButtonWithIcon extends Component {

    render() {
        return (
            <ButtonStyled
                disabled={this.props.isSubmitting}
                type={this.props.type ? this.props.type : 'button'}
                {...this.props} >
                <ButtonIconStyled className={this.props.icon} aria-hidden="true" />  {this.props.text}
            </ButtonStyled>
        );
    }
}
